import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import store from "@/store";
import { setTitle, setDescription } from "@/mixins/index.js";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      title: "自動車陸送の業界最安値に挑戦｜株式会社R.C.I.（アールシーアイ）",
      description:
        "「自動車陸送の業界最安値に挑戦」をモットーに株式会社R.C.I.（アールシーアイ）では中古車販売店様、一般貨物事業者様、保険会社様向けの自動車陸送を行っています。岐阜と沖縄を拠点に全国対応し、徹底した安全管理とドライバー教育のもと、お客様に安心してご依頼いただける環境を整えています。お値打ち価格で丁寧な対応をさせていただきます。",
    },
  },
];

const router = createRouter({
  mode: "history",
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition || to.meta.savedPosition === true) {
      return savedPosition;
    } else {
      return { top: 0, behavior: "smooth" };
    }
  },
});

router.beforeEach((to, from, next) => {
  let documentHead = document.getElementsByTagName("head");
  let gfjScript = document.createElement("script");
  gfjScript.type = "application/ld+json";
  if (to.meta.gfjScriptContent !== undefined) {
    gfjScript.innerHTML = to.meta.gfjScriptContent;
    documentHead[0].appendChild(gfjScript);
    //↑gfj用
  }
  setTitle(to.meta.title);
  setDescription(to.meta.description);
  store.dispatch("setRootUrl");

  if (to.path.slice(-1) === "/") {
    next();
  } else {
    next(to.path + "/");
  }
});

export default router;
