<template>
  <section
    id="company"
    class="padding-y-150"
    v-prlx.mobile.background="{
      direction: 'y',
      fromBottom: false,
      speed: 0.05,
    }"
  >
    <h2>Company&Shop Information</h2>
    <p class="description">会社・店舗情報</p>
    <div class="content">
      <div class="informations">
        <div class="info-title">
          <h4>会社概要</h4>
        </div>
        <div class="info-content">
          <div class="info-sub-title">会社名</div>
          <div class="info-name">株式会社R.C.I.（アールシーアイ）</div>
        </div>
        <div class="info-content">
          <div class="info-sub-title">代表者名</div>
          <div class="info-name">川上 貴史</div>
        </div>
        <div class="info-content">
          <div class="info-sub-title">住所</div>
          <div class="info-name">〒461-0005 愛知県名古屋市東区東桜1-10-36</div>
        </div>
      </div>
      <div class="informations">
        <div class="info-title">
          <h4>本店</h4>
        </div>
        <div class="info-content">
          <div class="info-sub-title">住所</div>
          <div class="info-name">
            〒501-6244 岐阜県羽島市竹鼻町丸の内11丁目52番
          </div>
        </div>
        <div class="info-content">
          <div class="info-sub-title">電話番号</div>
          <div class="info-name">
            <a href="tel:0583372801" class="phone-number">058-337-2801</a>
            （平日10時～17時）
          </div>
        </div>
        <div class="info-content">
          <div class="info-sub-title">FAX番号</div>
          <div class="info-name">
            <a href="tel:0583380472" class="fax-number">058-338-0472</a>
          </div>
        </div>
        <div class="info-content">
          <div class="info-sub-title">営業時間</div>
          <div class="info-name">9時～19時（定休日：日曜日）</div>
        </div>
      </div>
      <div class="informations">
        <div class="info-title">
          <h4>沖縄支社</h4>
        </div>
        <div class="info-content">
          <div class="info-sub-title">住所</div>
          <div class="info-name">〒905-0214 沖縄県国頭郡本部町字渡久地20-1</div>
        </div>
        <div class="info-content">
          <div class="info-sub-title">電話番号</div>
          <div class="info-name">
            <a href="tel:0980436590" class="phone-number">0980-43-6590</a>
          </div>
        </div>
        <div class="info-content">
          <div class="info-sub-title">営業時間</div>
          <div class="info-name">10時〜19時（定休日：日曜日）</div>
        </div>
      </div>
      <div class="informations">
        <div class="info-title">
          <h4>薮田南営業所</h4>
        </div>
        <div class="info-content">
          <div class="info-sub-title">住所</div>
          <div class="info-name">〒500-8384 岐阜市薮田南4丁目</div>
        </div>
        <div class="info-content">
          <div class="info-sub-title">営業時間</div>
          <div class="info-name">
            ご予約専用です。事前にお問い合わせをお願いいたします。
          </div>
        </div>
      </div>
      <div class="informations last-info">
        <div class="info-title">
          <h4>間島ヤード</h4>
        </div>
        <div class="info-content">
          <div class="info-sub-title">住所</div>
          <div class="info-name">〒501-6251 羽島市福寿町間島5丁目13番</div>
        </div>
        <div class="info-content">
          <div class="info-sub-title">営業時間</div>
          <div class="info-name">9時～19時（定休日：日曜日）</div>
        </div>
      </div>
      <p class="business-number">事業者番号：540001626</p>
      <p class="business-permit">
        一般貨物自動車運送事業/利用運送事業/回送運航許可
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: "InformationVue",
};
</script>

<style lang="scss" scoped>
section {
  background-image: url("../../assets/RCI_top_haikei002.webp");
  background-size: cover;
  @media screen and (max-width: 1100px) {
    background-image: url("../../assets/RCI_top_haikei002sp.webp");
  }
  .content {
    .informations {
      margin: 60px 0;
      .info-title {
        background-color: var(--sub2);
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .info-content {
        border-bottom: 1px solid var(--black);
        padding: 10px 0;
        display: flex;
        .info-sub-title {
          width: 25%;
          padding: 10px 0;
          display: flex;
          align-items: center;
          justify-content: center;
          @media screen and (max-width: 767px) {
            font-size: 16px;
          }
        }
        .info-name {
          width: 75%;
          padding: 10px 5%;
          border-left: 1px solid var(--black);
          text-decoration: none;
          @media screen and (max-width: 767px) {
            padding: 10px 3%;
          }
          .phone-number {
            color: var(--font);
            text-decoration: none;
            @media screen and (min-width: 768px) {
              // 現話番号のクリックを無効化する
              pointer-events: none;
              cursor: default;
            }
          }
          .fax-number {
            color: var(--font);
            text-decoration: none;
            pointer-events: none;
            cursor: default;
          }
        }
      }
    }
    .last-info {
      margin-bottom: 30px;
    }
    .business-number,
    .business-permit {
      font-size: 16px;
      text-decoration: none;
      pointer-events: none;
      cursor: default;
    }
  }
}
</style>
