<template>
  <section
    id="about"
    v-prlx.mobile.background="{
      direction: 'y',
      fromBottom: false,
      speed: 0.05,
    }"
  >
    <div class="content">
      <h1>About us</h1>
      <p class="description">株式会社 R.C.I. の自動車陸送について</p>
      <img src="@/assets/RCI_top_001.webp" alt="自動車陸送のイメージ" />
      <p>
        「自動車陸送の業界最安値に挑戦」をモットーに株式会社R.C.I.(アールシーアイ)では中古車販売店様、一般貨物事業者様、保険会社様向けの自動車陸送を行っています。岐阜を拠点に全国対応し、徹底した安全管理とドライバー教育のもと、お客様に安心してご依頼いただける環境を整えています。お値打ち価格で丁寧な対応をさせていただきます。沖縄支社も開設し、沖縄でもサービスを提供しています。
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: "AboutUs",
};
</script>

<style lang="scss" scoped>
section {
  background-image: url("../../assets/RCI_top_haikei001.webp");
  background-size: cover;
  @media screen and (max-width: 1100px) {
    background-image: url("../../assets/RCI_top_haikei001sp.webp");
  }
  .content {
    img {
      margin-bottom: 40px;
      @media screen and (max-width: 767px) {
        margin-bottom: 20px;
      }
    }
  }
}
</style>
